@use '../../../assets/customizations/customization';


.patient-pill>a.active {
    background-color: customization.$custom-main !important;
    color: #fff !important;
    text-decoration: none !important;
}

dl dd {
    margin-bottom: 0;
}